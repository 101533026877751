export const administrador = 'Administrador'
export const publicadorCont = 'Publicador de contenidos'
export const editorCont = 'Editor de contenidos'
export const gestorUser = 'Gestor de usuarios'
export const gestorStatis = 'Gestor de estadisticas'
export const dis = 'Diseñador'
export const publi = 'Publicidad'
export const vendor = 'Vendedor'
export const sponsor = 'Patrocinador'
export const gestorNotifications = 'Gestor de notificaciones'
export const exludeList =  ['Administrador subgrupos','demos','testers','ListaPlataforma','Gestor Contenidos - Interactvty']