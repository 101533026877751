export default [
  {
    path: '/fast-channels',
    name: 'fast-channels',
    meta: { activeItem: 'fast-channels', permission: ['contents.view_fastchannelchannel'] },

    component: () => import('@/views/fast-channels/FastChannels.vue'),
  },
  {
    path: '/fast-channels/history/:id',
    name: 'channel-history',
    meta: { activeItem: 'fast-channels', permission: ['contents.view_fastchannelchannel'] },
    component: () => import('@/views/fast-channels/ChannelsHistory.vue'),
  },
  {
    path: '/fast-channels/history/exportations/:id',
    name: 'history-exportations',
    meta: { activeItem: 'fast-channels', permission: ['contents.view_fastchannelchannel'] },
    component: () => import('@/views/fast-channels/HistoryExportations.vue'),
  },
]
