export default [
  {
    path: '/superuser/tokens',
    name: 'tokens',
    meta: { activeItem: 'tokens' },

    component: () => import('@/views/superuser/tokens.vue'),
  },
  {
    path: '/superuser/bucket',
    name: 'bucket',
    meta: { activeItem: 'bucket' },

    component: () => import('@/views/superuser/bucket.vue'),
  },
  {
    path: '/superuser/live',
    name: 'live',
    meta: { activeItem: 'live' },

    component: () => import('@/views/superuser/live.vue'),
  },
  {
    path: '/superuser/transcoding',
    name: 'transcoding',
    meta: { activeItem: 'transcoding' },

    component: () => import('@/views/superuser/transcoding.vue'),
  },
  {
    path: '/superuser/ads',
    name: 'ads',
    meta: { activeItem: 'ads' },

    component: () => import('@/views/superuser/ads.vue'),
  },
  {
    path: '/superuser/service',
    name: 'service',
    meta: { activeItem: 'service' },

    component: () => import('@/views/superuser/service.vue'),
  },
  {
    path: '/superuser/learning',
    name: 'learning',
    meta: { activeItem: 'learning' },

    component: () => import('@/views/superuser/learning.vue'),
  },
  {
    path: '/superuser/create-platform',
    name: 'create-platform',
    component: () => import('@/views/superuser/createPlatform.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/superuser/email',
    name: 'mail',
    component: () => import('@/views/superuser/mail.vue'),

  },
  {
    path: '/superuser/templates',
    name: 'templates',
    component: () => import('@/views/superuser/templates/templatesList.vue'),

  },
  {
    path: '/superuser/verificator',
    name: 'verificator',
    component: () => import('@/views/superuser/verificator/verificatorUser.vue'),

  },

]
