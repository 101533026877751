import * as constants from '@core/utils/constants'

export default [{
  path: '/displayAdvertising/campaigns',
  name: 'display-campaigns-list',
  meta: { activeItem: 'display-campaigns-list', rol: [constants.publi, constants.sponsor] },
  component: () => import('@/views/publicidadDisplay/displayCampaigns/DisplayCampaignsList.vue'),
},
{
  path: '/displayAdvertising/campaigns/create',
  name: 'display-campaigns-create',
  meta: { activeItem: 'display-campaigns-list', rol: [constants.publi, constants.sponsor] },
  component: () => import('@/views/publicidadDisplay/displayCampaigns/FormWizardDisplayCampaign.vue'),
},
{
  path: '/displayAdvertising/campaigns/edit/:id',
  name: 'display-campaigns-edit',
  meta: { activeItem: 'display-campaigns-list', rol: [constants.publi, constants.sponsor] },
  component: () => import('@/views/publicidadDisplay/displayCampaigns/EditDisplayCampaign.vue'),
},
{
  path: '/displayAdvertising/ads/:campaign',
  name: 'display-ads-list',
  meta: { activeItem: 'display-campaigns-list', rol: [constants.publi, constants.sponsor] },
  component: () => import('@/views/publicidadDisplay/displayAds/DisplayAdsList.vue'),
},
{
  path: '/displayAdvertising/ads/edit/:id/',
  name: 'display-ads-edit',
  meta: { activeItem: 'display-campaigns-list', rol: [constants.publi, constants.sponsor] },

  component: () => import('@/views/publicidadDisplay/displayAds/EditDisplayAds.vue'),

},
{
  path: '/displayAdvertising/ads/create/:campaign',
  name: 'display-ads-create',
  meta: { activeItem: 'display-campaigns-list', rol: [constants.publi, constants.sponsor] },

  component: () => import('@/views/publicidadDisplay/displayAds/FormWizardDisplayAds.vue'),

},
]
