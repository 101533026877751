import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    sizeWindow: 0,
    windowWidth: 0,
    shallShowOverlay: false,
    listOfCategories: [],
    listOfContents: [],
    platformConfiguration: {},
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    sizeWindow: state => {
      const { sizeWindow } = state
      return sizeWindow
    },
  },
  mutations: {
    SET_SIZE_WINDOW(state, val) {
      state.sizeWindow = val
    },
    SET_CATEGORY_COPY(state, val) {
      state.listOfCategories = val
    },
    SET_CONTENTS_COPY(state, val) {
      state.listOfContents = val
    },
    SET_PLATFORM_CONFIGURATION(state, val) {
      state.platformConfiguration = val
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
}
