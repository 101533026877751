import Vue from 'vue'

// axios
import Axios from 'axios'
// eslint-disable-next-line import/no-cycle
import JwtService from '@/auth/jwt/graphJwtService'

const baseURL = process.env.VUE_APP_BACK_URL

const axios = Axios.create({
  // You can add your headers here
  // ================================
  baseURL,
  // headers: {'X-Custom-Header': 'foobar'}
})
axios.defaults.baseURL = baseURL
const jwt = new JwtService(axios)

Vue.prototype.$http = jwt.axiosIns
export default jwt.axiosIns
