import { $themeConfig } from '@themeConfig'

let name
try {
  name = localStorage.getItem('userData') ? JSON.parse(decodeURIComponent(escape(window.atob(localStorage.getItem('userData'))))).profile.client.displayName : null
} catch (error) {
  name = null
}
export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    changeName: name,
  },
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    alterName(state, val) {
      state.changeName = val
      const getName = localStorage.getItem('userData') ? JSON.parse(decodeURIComponent(escape(window.atob(localStorage.getItem('userData'))))) : null
      let value = ''
      try {
        if (getName) {
          getName.profile.client.displayName = val
          value = window.btoa(unescape(encodeURIComponent(JSON.stringify(getName))))
          localStorage.setItem('userData', value)
        }
      } catch (error) {
        console.log(error)
      }
    },
    cleanData(state, val) {
      state.changeName = val
    },
  },
  actions: {
    alterName({ commit }, val) {
      commit('alterName', val)
    },
    cleanData({ commit }, val) {
      commit('cleanData', val)
    },
  },
}
