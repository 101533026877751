// eslint-disable-next-line import/no-cycle
import useJwt from '@core/auth/jwt/useJwt'
// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

const { jwt } = useJwt(axios, {
  loginEndpoint: '/jwt/token/',
  refreshEndpoint: '/jwt/token/refresh/',
  logoutEndpoint: '/jwt/logout/',
  storageTokenKeyName: 'access',
  storageRefreshTokenKeyName: 'refresh',
})
export default jwt
